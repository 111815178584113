import Link from 'next/link';
import React from 'react';
import { getCta } from 'utils/common';

const teamSize = 8

export const AllFAQs = [
  {
    question:
      'What is the difference between a Freemium and a Premium DAYMADE subscription?',
    answer: (
      <>
        It's all in the name! The Freemium subscription is totally free, but in
        order to win a prize you need to match 5 numbers. With a Premium
        subscription, you pay £2.95 per week and you start winning when you
        match just 1 number. So the chances of winning a prize with a Premium
        subscription are much higher than the chances of winning with a Freemium
        subscription. In fact, each week 1 in 3 Premium subscribers wins
        something. As a Premium subscriber, you also plant a tree each week and
        unlock all the prizes on our site, as well access to exclusive
        discounts.
      </>
    ),
    answerText:
      "It's all in the name! The Freemium subscription is totally free, but in order to win a prize you need to match 5 numbers. With a Premium subscription, you pay £2.95 per week and you start winning when you match just 1 number. So the chances of winning a prize with a Premium subscription are much higher than the chances of winning with a Freemium subscription. In fact, each week 1 in 3 Premium subscribers wins something. As a Premium subscriber, you also plant a tree each week and unlock all the prizes on our site, as well access to exclusive discounts.",
  },
  {
    question:
      'What is the difference between a Premium and a Lite subscription?',
    answer: (
      <>
        A Premium subscription is £2.95 per week. With a Premium subscription,
        you have access to all the prizes and you have the best chances of
        winning larger prizes. A Lite subscription starts at £1.50. With a Lite
        subscription you have access to prizes up to and including the Diamond
        category (and the DAYMAKERboost if you actively opted in for this). Your
        chances of winning a (non-Token) prize are lower than with a Premium
        subscription, and you do not play for the DAYMAKER jackpot prize. It’s
        the ideal choice if you want to spend a little less every week, while
        still dreaming of winning that epic holiday!
      </>
    ),
    answerText:
      'A Premium subscription is £2.95 per week. With a Premium subscription, you have access to all the prizes and you have the best chances of winning larger prizes. A Lite subscription starts at £1.50. With a Lite subscription you have access to prizes up to and including the Diamond category (and the DAYMAKERboost if you actively opted in for this). Your chances of winning a (non-Token) prize are lower than with a Premium subscription, and you do not play for the DAYMAKER jackpot prize. It’s the ideal choice if you want to spend a little less every week, while still dreaming of winning that epic holiday!',
  },
  {
    question: "What's included in the trips?",
    answer: (
      <>
        All the international travels include flights and accommodation for two people. While most trips
        are designed for 2 persons, we can customise the number of travellers
        based on the preferences of the winner, so long as it fits within the
        prize budget. On top of that, we add unforgettable activities and
        experiences to the trips - so they're truly one of a kind! The best
        thing is: you don't need to do anything. You choose the dates, we do the
        rest. You can find what exactly is included in your trip of choice by
        clicking on the prize and checking out 'what's included'.
      </>
    ),
    answerText:
      "All the international travels include flights and accommodation. Most trips also include meals and transport for two people. While most trips are designed for 2 persons, we can customise the number of travellers based on the preferences of the winner, so long as it fits within the prize budget. On top of that, we add unforgettable activities and experiences to the trips - so they're truly one of a kind! The best thing is: you don't need to do anything. You choose the dates, we do the rest. You can find what exactly is included in your trip of choice by clicking on the prize and checking out 'what's included'.",
    homePageFeatured: true,
  },
  {
    question: 'Has anyone ever won?',
    answer: (
      <>
        We've had more than 600,000 players win prizes since we launched, of
        which 1000+ won a travel prize! We highlight a winner on our Instagram
        every week and we highlight some winners right here on our website on
        the {''}
        <Link href="/winners">
          <a>Winners page</a>
        </Link>
        {''}. More of our winners have won lower value prizes (Bronze, Silver or
        Gold), as the chances of matching only a few numbers are so much better.
      </>
    ),
    answerText:
      "We've had more than 44,000 players win prizes since we launched, of which 100+ won a travel prize! More of our winners have won lower value prizes (Bronze, Silver or Gold), as the chances of matching up to 3 numbers are so much better.",
    homePageFeatured: true,
    homePageCindyVariantFeatured: true,
  },
  {
    question: "How do I know if I've won?",
    answer:
      "We'll always email all winners within two hours of the draw. If you don't reply within 2 weeks, we'll send you a reminder. Although we advise you to check your account page every now and then to see if you've won anything, in case our emails to you have ended up in your spam folder. If you want to check if you have the winning numbers, simply log in and visit our Results page.",
    homePageFeatured: true,
    homePageCindyVariantFeatured: true,
  },
  {
    question: 'What are the differences between your draws and raffles?',
    answer: (
      <>
        We have two <strong>Weekly Draws</strong> - one on Tuesday and one on
        Friday - where you choose 4 numbers between <strong>1 - 55</strong> and
        a Bonus Number between <strong>1 - 10</strong>. The winning numbers are
        drawn at 5pm on both days. For the Friday draw, winners are drawn live
        on Instagram (
        <a
          href="https://www.instagram.com/daymadeuk/"
          style={{ textDecoration: 'underline' }}
          target="_blank"
          rel="noreferrer"
        >
          @daymadeuk
        </a>
        ). For the Tuesday draw, winners are drawn using a random number
        generator supplied by{' '}
        <a
          href="https://www.random.org"
          style={{ textDecoration: 'underline' }}
          target="_blank"
          rel="noreferrer"
        >
          RANDOM.ORG
        </a>{' '}
        and you can opt-in to a DAYMAKERboost, meaning your jackpot value would
        increase from £25,000 to £100,000 in case of a win. This boost is not
        (yet) available for the Friday draw. {''}
        <Link href={getCta()?.url ?? ''}>
          <a style={{ margin: 0, fontWeight: 'bold' }}>Enter here</a>
        </Link>
        {''}.<br />
        <br />
        In our <strong>Pound Raffle</strong>, we draw the guaranteed winner
        every other day. {''}
        <Link href="/token-town/pound-raffle">
          <a style={{ margin: 0, fontWeight: 'bold' }}>Enter here</a>
        </Link>
        {''}.<br />
        <br />
        The <strong>Leaderboard Raffle</strong>: this is a monthly raffle that
        all DAYMADE players are entered into for free. Depending on how active
        you are as a player, you collect a bunch of loyalty points that give you
        even more chances of winning. As a subscriber you collect double the
        amount of loyalty points. Check out your position on the Leaderboard{' '}
        {''}
        <Link href="/leaderboard">
          <a style={{ margin: 0, fontWeight: 'bold' }}>here</a>
        </Link>
        {''}.<br />
        <br />
      </>
    ),
    answerText:
      'We have two Weekly Draws - one on Tuesday and one on Friday - where you choose 4 numbers between 1 - 55 and a Bonus Number between 1 - 10. The winning numbers are drawn at 5pm on both days. For the Friday draw, winners are drawn live on Instagram (@daymadeuk). For the Tuesday draw, winners are drawn using a random number generator supplied by RANDOM.ORG and you can opt-in to a DAYMAKERboost, meaning your jackpot value would increase from £25,000 to £100,000 in case of a win. This boost is not (yet) available for the Friday draw. In our Pound Raffle, we draw the guaranteed prize winner every other day. The Leaderboard Raffle is a monthly raffle that all DAYMADE players are entered into for free. Depending on how active you are as a player, you collect a bunch of loyalty points that give you even more chances of winning. Paid subscribers collect double loyalty points on each action.',
    homePageFeatured: true,
  },
  {
    question: "I can't watch the live draw, how will I know the draw results?",
    answer: (
      <>
        Not to worry! We'll also post the winning numbers on our results page.
        The only way to watch the live draw currently is by tuning in to our
        Instagram. We also post all live draws immediately after on our{' '}
        <a
          href="https://www.instagram.com/daymadeuk/channel"
          target="_blank"
          rel="noreferrer"
        >
          IGTV
        </a>
        , where you can view them at any time post-draw. If you have a
        suggestion for another platform, give us a shout via our{' '}
        <a
          target="_blank"
          rel="noreferrer"
          href="https://daymade.zendesk.com/hc/en-gb/requests/new?ticket_form_id=0657"
        >
          contact form
        </a>
        .
      </>
    ),
    answerText: (
      <>
        "Not to worry! We'll also post the winning numbers on our results page.
        The only way to watch the live draw currently is by tuning in to our
        Instagram. We also post all live draws immediately after on our IGTV,
        where you can view them at any time post-draw. If you have a suggestion
        for another platform, give us a shout via our
        <a
          target="_blank"
          rel="noreferrer"
          href="https://daymade.zendesk.com/hc/en-gb/requests/new?ticket_form_id=0657"
        >
          contact form
        </a>
        .
      </>
    ),
  },
  {
    question:
      'Are any of the local prizes on your site available in my city/town?',
    answer:
      "We always find an experience prize for you in your nearest by city. It could be that not all the options are available in your city, but there's always at least 2 or 3 available!",
  },
  {
    question: 'How do I redeem my prize?',
    answer:
      "You will have received an email confirming you won a prize (check out spam if you didn't). Simply reply to that email with your preferred prize! If it's an experience or travel prize, let us know your preferred date and location. If it's a physical prize, let us know your delivery address. You can then leave it in our safe hands and we'll get it all sorted for you!",
  },
  {
    question: 'What is a DAYMADE Token?',
    answer:
      'Tokens are our way of rewarding you for matching even just 1 number.  You can collect Tokens and redeem them for free entries into our various games or for prizes on our site.',
  },
  {
    question: 'Is DAYMADE a scam?',
    answer: (
      <>
        No, but we welcome healthy scepticism 😉! It's of course all a matter
        of chance and you are much more likely to match 2 numbers than 5
        numbers. But with prizes big and small, the chance of winning something
        is in your favour! We highlight winners on our site (check out the
        'winners' page) and we highlight a winner every week on our Instagram
        page. We also have an Excellent rating on Trustpilot. Feel free to email
        us via our{' '}
        <a
          target="_blank"
          rel="noreferrer"
          href="https://daymade.zendesk.com/hc/en-gb/requests/new?ticket_form_id=0657"
        >
          contact form
        </a>{' '}
        if you still don't trust us and we can answer any questions you may
        have!
      </>
    ),
  },
  {
    question: 'How do I cancel my subscription?',
    answer: (
      <>
        You can cancel your subscription at anytime from the{' '}
        <Link href="/account/subscriptions">
          <a>My Subscription page</a>
        </Link>
        . The cancellation will have an immediate effect. If you are still
        struggling, just drop us an email via our{' '}
        <a
          target="_blank"
          rel="noreferrer"
          href="https://daymade.zendesk.com/hc/en-gb/requests/new?ticket_form_id=0657"
        >
          contact form
        </a>{' '}
        and we'll get it done for you. Please allow two working days for us to
        get back to you.
      </>
    ),
    answerText:
      "You can cancel your subscription at anytime from the My Subscription page. The cancellation will have an immediate effect. If you are still struggling, just drop us a email at team@daymade.co.uk and we'll get it done for you. Please allow two working days for us to get back to you.",
  },
  {
    question: 'How do I change my numbers?',
    answer: (
      <>
        You can change your numbers, as well as your number of entries, for your
        subscription from the{' '}
        <Link href="/account/subscriptions">
          <a>My Subscription page</a>
        </Link>
        . This is optional. If you don't edit your numbers, your chosen lucky
        numbers will automatically roll over on a weekly basis.
      </>
    ),
    answerText:
      "You can change your numbers, as well as your number of entries, for your subscription from the My Subscription page. This is optional. If you don't edit your numbers, your chosen lucky numbers will automatically roll over on a weekly basis.",
  },
  // { // Don't have insurance right now.
  //   question: "How can you afford to pay out my prize?",
  //   answer: "We are paying a prize insurance, which means we are insured for all the different prize tiers and pay a weekly insurance variable to the number of entries in the prize draw that week. That way we are able to pay out all the prizes, despite being a startup!"
  // },
  {
    question: "What's the alternative way of entering?",
    answer: (
      <>
        You can post us a letter with your email address and we'll enter you
        into the prize draw with one entry for free. For full details, please
        visit our{' '}
        <Link href="/alternative-entry-method">
          <a>alternative entry page</a>
        </Link>
        .
      </>
    ),
    answerText:
      "You can post us a letter with your email address and we'll enter you into the prize draw with one entry for free. For full details, please visit our alternative entry page.",
  },
  {
    question: 'What is the chance of winning DAYMADE?',
    answer:
      'The chance of winning is based on the amount of numbers you match. Of course matching 2 numbers is much more likely than matching all 5. A mathematical explanation of the chances is explained on our Terms & Conditions page. There is on average a 1 in 3 chance of winning something. The far majority of those wins will be in the form of tokens or treats as that’s the smallest winnable prize!',
  },
  {
    question: 'Do I need Instagram to play DAYMADE or see the results?',
    answer:
      'No. You can also find our results right here on our website under the Results page. You will also be emailed the results every Tuesday and Friday after the draw.',
  },
  {
    question: 'Can I choose the dates for my DAYMADE trip?',
    answer:
      "The choice of dates is entirely up to you if you win a Diamond, DAYMAKER or DAYMAKERboost trip. Some of the itinerary elements are subject to availability but if you were to miss a certain activity or restaurant booking, we'll get you something similarly great! For the Platinum prizes, you can also give us 3 preferred travel dates and we’ll find an option for you that suits!",
  },
  {
    question:
      'I want to change an activity or element in the itinerary, is that possible?',
    answer:
      'Yep, as long as it fits within the prize value, we can make your trip fully bespoke to your wishes!',
  },
  {
    question: 'Can I choose who I bring on the trip?',
    answer: 'Obvs!',
  },
  {
    question: 'Can I bring my family on the DAYMADE trip?',
    answer:
      "Most prize itineraries are for 2 people, but we want to make all the prizes as bespoke as possible. Most of our experiences are customisable for families, although it will change the itinerary somewhat (and what is included). The itinerary is flexible - the prize value is not. For example, if we can find a trip for a family of 4 or 5 to Bali for 7 days (incl. flight & hotel) for the same value as the original Platinum prize, we will definitely do so if that is what you wish! It may however mean that the yoga retreat won't be included.",
  },
  {
    question:
      'If I choose a physical prize, how long will it take for my DAYMADE prize to arrive?',
    answer: (
      <>
        Delivery times for packages vary based on which of the prizes you
        choose. Some may take 1 day, others may take 1 month. It should not take
        longer than 4 weeks. If you want to track your order, feel free to mail
        us via our{' '}
        <a
          target="_blank"
          rel="noreferrer"
          href="https://daymade.zendesk.com/hc/en-gb/requests/new?ticket_form_id=0657"
        >
          contact form
        </a>{' '}
        to check this for you!
      </>
    ),
  },
  {
    question:
      'How long does it take for the DAYMADE team to follow up, after I chose a prize?',
    answer:
      'It can take up to 5 working days for the DAYMADE team to get back to you, confirming your prize choice and informing you of next steps (if any) or simply that the order is on its way!',
  },
  {
    question: 'Does my prize expire?',
    answer:
      "If you haven't let us know your prize of choice within 28 days of the draw in which you won, your prize will expire. We will try and send you follow ups where we can, but please make sure to check your account at least once per month to make sure you didn't miss out on any wins (as our emails may sometimes end up in your spam folder). Once you choose your prize, your prize is valid for at least 6 to 18 months, depending on your prize of choice.",
  },
  {
    question: 'Can I change my prize once I have already chosen it?',
    answer:
      "Once you've selected your prize and it has been sent out to you, you unfortunately cannot change it. We have a lot of prize options available for you to choose from, so make sure you pick your absolute favourite so you don't regret it later!",
  },
  {
    question: 'Is DAYMADE real?',
    answer: (
      <>
        Yes, we are a team of {teamSize} real people (Callum, Andrew, Violet, Tim, David, Reshat,
        Jasmin & Lisa — find out more about us {''}
        <Link href="/about-us">
          <a style={{ margin: 0, fontWeight: 'bold' }}>here</a>
        </Link>
        {''}) and we have weekly draws every Tuesday and Friday at 5pm. Tune
        into our Instagram Live where you can witness our realness (
        <a
          href="https://www.instagram.com/daymadeuk/"
          target="_blank"
          style={{ textDecoration: 'underline' }}
          rel="noreferrer"
        >
          @daymadeuk
        </a>
        ). If you want some objective peace of mind before entering any of our
        draws, check out our{' '}
        <a
          href="https://uk.trustpilot.com/review/daymade.co.uk"
          target="_blank"
          style={{ margin: 0, fontWeight: 'bold' }}
          rel="noreferrer"
        >
          DAYMADE reviews on Trustpilot
        </a>
        .
      </>
    ),
    answerText:
      'Yes, we are a team of 7 real people (Callum, Andrew, Violet, Reshat, Jasmin & Lisa) and we have weekly draws every Tuesday and Friday at 5pm. Tune into our Instagram Live where you can witness our realness If you want some objective peace of mind before entering any of our draws, check out our DAYMADE reviews on Trustpilot.',
  },
  {
    question: 'There is an error on the DAYMADE site, what do I do?',
    answer: (
      <>
        Please email us directly via our{' '}
        <a
          target="_blank"
          rel="noreferrer"
          href="https://daymade.zendesk.com/hc/en-gb/requests/new?ticket_form_id=0657"
        >
          contact form
        </a>
        . We will try and fix the issue as soon as we’re able. We are very
        thankful for players who flag issues to us, so we can make sure as few
        people as possible are affected!
      </>
    ),
  },
  {
    question: 'Who are DAYMADE?',
    answer: (
      <>
        We are a team of {teamSize} travel-lovers based in London (Callum, Andrew, Violet, Reshat, , Jasmin, Tim & Lisa) who work tirelessly to deliver a great experience.
        <br />
        DAYMADE is a prize platform where players can win incredible lifestyle
        prizes and all-inclusive holidays and local experiences, while
        supporting a good cause. DAYMADE was born of the belief that experiences
        are more valuable than money, and we work hard to offer our players a
        wide range of prizes that are fun and memorable.
        <br />
        <br />
        Our weekly draws offers players a chance to win epic prizes big and
        small - from afternoon tea for two or a DIY doughnut kit to an
        all-inclusive holiday to South Africa or a full home make-over. You pick
        your prize and we handle the rest!
        <br />
        <br />
        Aside from our weekly draws, we also host a Pound Raffle as well as a
        free daily draw with a chance to win an epic holiday.
        <br />
        <br />
        At DAYMADE, our players are at the heart of everything we do, and we
        pride ourselves on great customer service, credibility, and
        transparency. Over time, we have built a great community of players with
        equal passion for travel, the environment and making memories! All our
        draws, prizes and products are sourced with input from players. More
        recently, we have introduced a leaderboard, to reward our community for
        their loyalty and give them even more chances to win.
        <br />
        <br />
        Tune into our Instagram live draw, every Friday at 5pm, to see how the
        winning numbers are drawn.
        <br />
        <br />
        <Link href="/about-us">
          <a>Learn more →</a>
        </Link>
      </>
    ),
    answerText:
      'DAYMADE is a prize platform where players can win incredible at-home prizes and all-inclusive holidays and local experiences, while supporting a good cause. DAYMADE was born of the belief that experiences are more valuable than money, and we work hard to offer our players a wide range of prizes that are fun and memorable.',
    homePageFeatured: true,
    homePageCindyVariantFeatured: true,
  },
  {
    question: 'Are the trips and experiences insured?',
    answer:
      'All trips and experiences are insured/ATOL protected in the case of an unexpected cancellation (for example - during covid lockdowns or when flights are getting cancelled). We advise all winners to take a personal travel insurance if you haven’t already, to cover yourself for pre- and in-trip events.',
  },
  {
    question: 'What is the DAYMAKERboost?',
    answer:
      'The DAYMAKERboost is an optional add-on to your subscription. You can add this boost when you check-out for the first time, or you can do it from your account right here. The DAYMAKERboost gives you a ‘boost’ on the jackpot prize. It means that if you match 5 numbers, your jackpot prize will quadruple! This means you’ll get a prize worth £100,000, instead of the standard £25,000 jackpot.',
  },
  {
    question: 'I would like to close my account — how do I do that?',
    answerText: "We're sorry to hear that you would like to close your account. You can do so here",
    answer: <>
      We're sorry to hear that you would like to close your account.
      You can do so {''}
      <Link href="https://daymade.zendesk.com/hc/en-gb">
        <a
          rel="noopener noreferrer"
          target="_blank"
          style={{ fontWeight: 'bold', margin: 0, cursor: 'pointer' }}
        >
          here
        </a>
      </Link>
      {''}.
    </>,

  }
];

export const HomePageFAQs = AllFAQs.filter((faq) => faq.homePageFeatured);

export const HomePageCindyVariantFAQs = AllFAQs.filter(
  (faq) => faq.homePageCindyVariantFeatured
);
