import { ReactNode, useState } from "react";
import Chevron from "components/Chevron";

interface IFaqCardProps {
  question: ReactNode;
  answer: ReactNode;
  preExpanded?: boolean;
  style?: React.CSSProperties;
  questionStyle?: React.CSSProperties;
  answerStyle?: React.CSSProperties;
  hideSeparator?: boolean;
}

const FaqCard = ({
  question,
  answer,
  preExpanded = false,
  style,
  questionStyle,
  answerStyle,
  hideSeparator = false,
}: IFaqCardProps) => {
  const [expanded, setExpanded] = useState(preExpanded);

  return (
    <div className="faqCard" style={style}>
      <div
        className="question"
        onClick={() => setExpanded(!expanded)}
        style={questionStyle}
      >
        {question}
        <Chevron
          direction="down"
          reversed={expanded}
          style={{
            margin: "0 0 0 0.8em",
          }}
        />
      </div>

      {expanded && (
        <div style={{ padding: "0 2em 2em 2em", ...answerStyle }}>
          <div
            className="separator"
            style={{ width: "100%", margin: "0 0 2em 0" }}
          />
          <p className="answer">{answer}</p>
        </div>
      )}

      <style jsx>{`
        .faqCard {
          width: 100%;
          background-color: white;
          border-radius: 3px;
          box-shadow: 0 1px 3px gray;
        }

        .question {
          font-weight: bold;
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding: 2em;
          cursor: pointer;
        }

        .separator {
          ${hideSeparator ? "display: none;" : ""}
        }

        .answer {
          color: #666;
        }
      `}</style>
    </div>
  );
};

export default FaqCard;
